<template>
  <div id="contractExit">
    <Back title="退租申请"></Back>
    <main>
      <!-- 租客信息 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>租客信息</div>
          <div class="title-right">
            <div class="title-right theme-color"></div>
          </div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">客户名称</span
            ><span>{{ tenantInfo.tenantName }}</span>
          </p>
          <p>
            <span class="info-color">客户联系人</span
            ><span>{{ tenantInfo.contactUserName }}</span>
          </p>
          <p>
            <span class="info-color">联系人电话</span
            ><span>{{ tenantInfo.contactUserMobile }}</span>
          </p>
          <p>
            <span class="info-color">合同面积</span
            ><span>{{ contractInfo.quantityStr }}</span>
          </p>
          <p>
            <span class="info-color">合同起止时间</span
            ><span
              >{{ formatterDate(contractInfo.effDate, "yyyy-MM-dd") }} ~
              {{ formatterDate(contractInfo.expDate, "yyyy-MM-dd") }}</span
            >
          </p>
          <p>
            <span class="info-color">合同编号</span
            ><span>{{ contractInfo.contractCode }}</span>
          </p>
        </div>
      </section>
      <!-- 房源信息 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>房源信息</div>
          <div class="title-right">
            <div class="title-right theme-color"></div>
          </div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">所在园区</span
            ><span>{{ contractInfo.projectName }}</span>
          </p>
          <p>
            <span class="info-color">房源信息</span
            ><span class="flex-column" v-if="contractInfo.resourceDtos"
              ><span
                v-for="item in contractInfo.resourceDtos"
                :key="item.resourceId"
                >{{ item.unitName }}-{{ item.resourceName }}</span
              ></span
            >
          </p>
        </div>
      </section>
      <!-- 退租日期 -->
      <section class="cell-item">
        <van-cell
          is-link
          :value="params.terminateDate"
          @click="terminateDateShow = true"
        >
          <template #title>
            <i class="title-icon"></i>
            <span class="title-span">退租日期</span>
          </template>
        </van-cell>
      </section>
      <!-- 应收账单 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>应收账单</div>
          <div class="title-right">
            <div class="title-right" :class="changeClass(yszdSum)">{{ yszdSum }}</div>
          </div>
        </div>
        <div class="block-content">
          <div
            class="van-hairline--bottom"
            style="padding:.1rem"
            v-for="item in yszdList"
          >
            <p class="flex-column-between">
              <p class="flex-between"><span class="info-color">{{ item.exacctName }}</span><span>{{ formatterDate(item.cycleStart,'yyyy-MM-dd') }}~{{ formatterDate(item.cycleEnd,'yyyy-MM-dd') }}</span></p>
              <p class="flex-between"><span class="info-color">账单金额</span><span>{{ item.billAmount }}元</span></p>
              <p class="flex-between"><span class="info-color">实际金额</span><span>{{ item.settledBillAmount||0}}元</span></p>
              <p class="flex-between"><span class="info-color">已收金额</span><span>{{ item.paidAmount }}元</span></p>               
              <p class="flex-between"><span class="info-color">剩余应收</span><span>{{ item.settledUnpaidAmount||0 }}元</span></p>               
            </p>
          </div>
        </div>
      </section>
      <!-- 预付费 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>预付费</div>
          <div class="title-right">
            <div
              class="title-right"
              :class="changeClass(prepayment)"
            >
              {{ prepayment }}
            </div>
          </div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">水电费余额</span
            ><span>{{ exitInfo.prePaid }}元</span>
          </p>
          <p>
            <span class="info-color">钱包余额</span
            ><span>{{ exitInfo.cash }}元</span>
          </p>
        </div>
      </section>
      <!-- 押金 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>押金</div>
          <div class="title-right">
            <div class="title-right" :class="changeClass(-cashPledge)">{{-cashPledge}}</div>
          </div>
        </div>
         <div class="block-content">
          <div
            class="van-hairline--bottom"
            style="padding:.1rem"
          >
            <p class="flex-column-between">
              <p class="flex-between"><span class="info-color">租房押金</span><span>{{ zfyjList&&zfyjList.settledBillAmount}}元</span></p>
              <p class="flex-between"><span class="info-color">已收金额</span><span>{{ zfyjList&&zfyjList.paidAmount }}元</span></p>    
              <p class="flex-between"><span class="info-color">应退金额</span><span>{{ zfytSum }}元</span></p>    
              <p class="flex-between mr-t"><span class="info-color">没收押金</span><span><van-checkbox v-model="zjyjFlag" shape="square"></van-checkbox></span></p>    
            </p>
          </div>
          <div
            class="van-hairline--bottom"
            style="padding:.1rem"
          >
            <p class="flex-column-between">
              <p class="flex-between"><span class="info-color">物业押金</span><span>{{ wyyjList&&wyyjList.settledBillAmount}}元</span></p>
              <p class="flex-between"><span class="info-color">已收金额</span><span>{{ wyyjList&&wyyjList.paidAmount }}元</span></p>  
              <p class="flex-between"><span class="info-color">应退金额</span><span>{{ wyytSum }}元</span></p>    
              <p class="flex-between mr-t"><span class="info-color">没收押金</span><span><van-checkbox v-model="wyyjFlag" shape="square"></van-checkbox></span></p>    
            </p>
          </div>
        </div>
      </section>
      <!-- 其他费用 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>其他费用</div>
          <div class="title-right">
            <div class="title-right theme-color" @click="onAddOther">添加</div>
          </div>
        </div>
        <div class="block-content">
          <template v-if="params.contractTerminateFeeVoList.length">
            <div class="other-cost" v-for="item in params.contractTerminateFeeVoList" :key="item.otherId">
              <van-icon name="clear" class="other-cost-del" size="24" color="rgba(0, 0, 0, 0.5)" @click="onDelOther(item.otherId)"/>
              <van-field
                readonly
                clickable
                label="费用类型"
                v-model="item.costTypeName"
                placeholder="请选择费用类型"
                @click="onOtherShow(item.otherId)"
              />
               <van-field v-model="item.amount" type="number" label="金额" placeholder="请输入金额"/>
               <van-field
                v-model="item.remark"
                rows="1"
                autosize
                label="备注"
                type="textarea"
                placeholder="请输入备注"
                maxlength="50"
                show-word-limit
              />
            </div>
          </template>
          <template v-else>
            暂无其他费用
          </template>
        </div>
      </section>      
      <!-- 费用总计 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>费用总计</div>
          <div class="title-right">
            <div class="title-right" 
            :class="changeClass(finalSettlement)">
            {{ finalSettlement }}
            </div>
          </div>
        </div>
        <div class="block-content">
          <p>
            <span class="info-color">应收账单</span
            ><span :class="changeClass(yszdSum)">{{ yszdSum }}元</span>
          </p>
          <p>
            <span class="info-color" >预付费账单</span
            ><span :class="changeClass(prepayment)">{{ prepayment }}元</span>
          </p>
          <p>
            <span class="info-color">押金账单</span
            ><span :class="changeClass(-cashPledge)">{{ -cashPledge }}元</span>
          </p>
          <p>
            <span class="info-color">其他费用</span
            ><span :class="changeClass(otherSum)">{{ otherSum }}元</span>
          </p>
        </div>
      </section>
      <!-- 原因 -->
      <section class="block-item">
        <div class="block-title van-hairline--bottom">
          <div class="title-left"><i></i>原因</div>
          <div class="title-right">
            <div class="title-right theme-color"></div>
          </div>
        </div>
        <div class="block-content">
            <van-field
              readonly
              clickable
              label="退租原因"
              v-model="params.resourceTerminateReason"
              @click="caseShow = true"
              placeholder="请选择退租原因"
              required 
            />
            <van-field
              v-model="params.remark"
              rows="1"
              autosize
              label="退租备注"
              type="textarea"
              placeholder="请输入退租备注"
              maxlength="50"
              show-word-limit
            />   
            <!-- 图片上传 -->
            <div class="upload">
              <p>图片上传</p>
              <van-uploader v-model="fileList" multiple :max-count="5" :after-read="afterRead"/>
            </div>
        </div>
      </section>
      <!-- 保存提交 -->
      <section class="block-foot">
        <van-button type="info" block @click="onSubmit">保存并审批</van-button>
      </section>
    </main>
    <!-- 退租日期弹框 -->
    <van-calendar v-model="terminateDateShow" @confirm="onConfirm" :max-date="maxDate"/>
    <!-- 其他费用类型弹框 -->
    <van-popup v-model="otherCostShow" round position="bottom">
      <van-picker
        show-toolbar
        :columns="costTypeOpt"
        @cancel="otherCostShow = false"
        @confirm="onOtherCostConfirm"
      />
    </van-popup>
    <!-- 退租原因弹框 -->
    <van-popup v-model="caseShow" round position="bottom">
      <van-picker
        show-toolbar
        :columns="caseTypeOpt"
        @cancel="caseShow = false"
        @confirm="onCaseConfirm"
      />
    </van-popup>
    <!-- 审批弹窗 -->
    <van-popup 
      v-model="approveShow" 
      position="right" 
      :style="{ 
        width: '90%',
        height: '100%' 
      }">
      <Approve processType="3" @success="onCloseSon" :businessId="contractInfo.contractId"></Approve>
    </van-popup>
  </div>
</template>

<script>
import Back from "@/components/back";
import Approve from "@/components/approve";
import {
  getContractExitInfo,
  getContractExitSave,
  getContractExitImg,
  getContractExitImgSave,
} from "@/api/contract/contract";
import { getUploadToken, getFileId } from "@/api/common/common";
import { costTypeOpt, caseTypeOpt } from "@/db/contract";
import { formatterDate, formatDate, monthLater } from "@/utils/utils";
import { Toast } from "vant";
import OSS from "ali-oss";
import Exif from "exif-js";
export default {
  components: { Back, Approve },
  data() {
    return {
      formatterDate,
      formatDate,
      monthLater,
      costTypeOpt,
      caseTypeOpt,
      exitInfo: "", //总信息
      tenantInfo: "", //租客信息
      contractInfo: "", //合同信息
      billList: [], //账单明细
      terminateDateShow: false, //退租日期弹框开关
      otherCostShow: false, //其他费用弹框开关
      caseShow: false, //退租原因弹框开关
      zjyjFlag: true, //是否没收租房押金
      wyyjFlag: true, //是否没收物业押金
      zfytSum: 0, //租房押金应退
      wyytSum: 0, //物业押金应退
      params: {
        unitPricePrecision: "", //单价保留小数点位数
        contractId: "", //合同ID
        contractStatus: "", //合同状态
        approveStatus: "", //审批状态
        customizeProcessId: "", //流程Id
        accountId: "", //账户id
        tenantName: "", //租户名称
        contractCode: "", //合同编号
        quantity: "", //租赁面积
        terminateDate: formatterDate(Date.now(), "yyyy-MM-dd"), //退租日期
        remark: "", //退租备注
        confiscateDeposit: true, //没收押金
        confiscatePropertyDeposit: true, //没收物业押金
        terminateReason: "", //退租理由
        resourceTerminateReason: "", //退租理由描述
        contractTerminateFeeVoList: [], //其他费用列表
        billVoList: "", //结算账单
        finalSettlement: "", //结算总额
      },
      maxDate: new Date(monthLater(new Date())),
      otherId: 0, //其他费用类型自增ID
      checkedOtherId: "", //当前点击的弹框id
      approveShow: false, //审批弹出窗开关
      ossClient: "", //oss上传密钥对象
      fileList: [], //图片
    };
  },
  created() {
    this.params.contractId = this.$route.query.contractId;
    this.getContractExitInfo();
    this.getContractExitImg();
  },
  computed: {
    // 应收账单
    yszdList() {
      return this.billList.filter((i) => i.exacctId == 1 || i.exacctId == 5);
    },
    // 应收账单金额
    yszdSum() {
      let yszdList = this.billList.filter(
        (i) => i.exacctId == 1 || i.exacctId == 5
      );
      let total = 0;
      for (let i of yszdList) {
        let num = i.settledUnpaidAmount || 0;
        total += num;
      }
      return total.toFixed(2);
    },
    // 租房押金
    zfyjList() {
      return this.exitInfo.depositAmountBillDtos.filter(
        (i) => i.exacctId == 2
      )[0];
    },
    // 物业押金
    wyyjList() {
      return this.exitInfo.depositAmountBillDtos.filter(
        (i) => i.exacctId == 6
      )[0];
    },
    // 预付费总金额
    prepayment() {
      return -this.exitInfo.prePaid + this.exitInfo.cash;
    },
    // 押金应退
    cashPledge() {
      return this.zfytSum + this.wyytSum;
    },
    // 其他费用金额
    otherSum() {
      let total = 0;
      for (let i of this.params.contractTerminateFeeVoList) {
        total += Number(i.amount);
      }
      return total;
    },
    finalSettlement() {
      let finalSettlement = (
        Number(this.prepayment) +
        Number(this.yszdSum) -
        Number(this.cashPledge) +
        Number(this.otherSum)
      ).toFixed(2);
      this.params.finalSettlement = finalSettlement;
      return finalSettlement;
    },
  },
  watch: {
    // 【监听】租房押金应退
    zjyjFlag() {
      this.params.confiscateDeposit = this.zjyjFlag;
      return this.zjyjFlag
        ? (this.zfytSum = 0)
        : (this.zfytSum = this.zfyjList.paidAmount);
    },
    // 【监听】物业押金应退
    wyyjFlag() {
      this.params.confiscatePropertyDeposit = this.wyyjFlag;
      return this.wyyjFlag
        ? (this.wyytSum = 0)
        : (this.wyytSum = this.wyyjList.paidAmount);
    },
  },
  methods: {
    // 【转换】是否大于0加类
    changeClass(params) {
      return params >= 0 ? "success-color" : "danger-color";
    },

    // 【转换】总金额
    totalSum(a, b, c, d) {
      return a + b + c + d;
    },

    // 【请求】退租详细
    getContractExitInfo() {
      let params = {
        contractId: this.params.contractId,
        date: this.params.terminateDate,
      };
      getContractExitInfo(params)
        .then((res) => {
          if (res.success) {
            let data = res.data;
            this.exitInfo = data;
            this.contractInfo = data.contractInfoVo;
            this.tenantInfo = data.tenantDto;
            this.billList = data.incomeBillDtos;
          } else {
            Toast(res.msg);
          }
        })
        .then((res) => {
          this.params.unitPricePrecision = this.contractInfo.unitPricePrecision;
          this.params.contractStatus = this.contractInfo.contractStatus;
          this.params.approveStatus = this.contractInfo.approveStatus;
          this.params.customizeProcessId = this.contractInfo.customizeProcessId;
          this.params.accountId = this.exitInfo.accountId;
          this.params.tenantName = this.tenantInfo.tenantName;
          this.params.contractCode = this.contractInfo.contractCode;
          this.params.quantity = this.contractInfo.quantity;
          this.params.billVoList = this.exitInfo.incomeBillDtos.filter(
            (i) => i.exacctId == 1 || i.exacctId == 5
          );
        });
    },

    // 【请求】退租申请
    getContractExitSave() {
      let data = {
        contractTerminateInfo: this.params,
      };
      getContractExitSave(data).then((res) => {
        if (res.success) {
          Toast("保存成功");
          this.approveShow = true; //审批弹窗开关
        } else {
          Toast("保存失败");
        }
      });
    },
    // 【请求】退租图片回显
    getContractExitImg() {
      let data = this.params.contractId;
      getContractExitImg(data).then((res) => {
        if (res.success) {
          if (res.data) {
            for (let i of res.data) {
              i.url = i.fileUrl;
            }
          }
          this.fileList = res.data;
        }
      });
    },

    // 【请求】退租图片保存
    getContractExitImgSave() {
      let list = this.fileList;
      let fileId = [];
      if (list) {
        for (let i of list) {
          fileId = [...fileId, i.fileId];
        }
      }
      let data = {
        contractAttachType: 4,
        contractId: this.params.contractId,
        fileId: fileId.toString(),
      };
      getContractExitImgSave(data).then((res) => {
        if (res.success) {
          Toast("图片保存成功");
        } else {
          Toast("图片保存失败");
        }
      });
    },

    // 【监听】退租日期
    onConfirm(date) {
      this.terminateDateShow = false;
      this.params.terminateDate = this.formatterDate(date, "yyyy-MM-dd");
      this.getContractExitInfo();
    },

    // 【监听】添加其他费用
    onAddOther() {
      this.otherId++;
      const list = {
        costType: 7, //费用类型
        amount: "", // 金额
        remark: "", //备注
        otherId: this.otherId, //备注
      };
      this.params.contractTerminateFeeVoList = [
        ...this.params.contractTerminateFeeVoList,
        list,
      ];
    },

    // 【监听】添加其他费用
    onDelOther(otherId) {
      let index = this.params.contractTerminateFeeVoList.findIndex(
        (i) => i.otherId == otherId
      );
      this.params.contractTerminateFeeVoList.splice(index, 1);
    },

    // 【监听】其他费用类型id
    onOtherShow(otherId) {
      this.checkedOtherId = otherId;
      this.otherCostShow = true;
    },

    // 【监听】其他费用类型选中
    onOtherCostConfirm(value) {
      this.params.contractTerminateFeeVoList.filter(
        (i) => i.otherId == this.checkedOtherId
      )[0].costType = value.value;
      this.params.contractTerminateFeeVoList.filter(
        (i) => i.otherId == this.checkedOtherId
      )[0].costTypeName = value.text;
      this.otherCostShow = false;
    },

    // 【监听】其他费用类型选中
    onCaseConfirm(value) {
      this.params.terminateReason = value.value.toString(); //退租理由
      this.params.resourceTerminateReason = value.text; //退租原因描述
      this.caseShow = false;
    },

    // 【监听】图片上传
    afterRead(file) {
      let _this = this;
      file.status = "uploading";
      file.message = "上传中...";
      // 获取上传密钥
      getUploadToken().then((res) => {
        const { accessKeyId, accessKeySecret, securityToken } =
          res.data.credentials;
        const client = new OSS({
          accessKeyId,
          accessKeySecret,
          stsToken: securityToken,
          bucket: "static-hiyunyu-com", //存储空间名称
          region: "oss-cn-hangzhou", //申请oss服务时的区域
        });

        //去获取拍照时的信息，解决拍出来的照片旋转问题
        const Orientation = "";
        Exif.getData(file, function () {
          Orientation = Exif.getTag(this, "Orientation");
        });

        const File = file.file;
        const reader = new FileReader();
        reader.readAsDataURL(File);
        reader.onload = function () {
          const image = new Image();
          image.src = this.result;
          image.onload = function () {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            // 图片原始尺寸
            const originWidth = this.width;
            const originHeight = this.height;
            // 最大尺寸限制
            const maxWidth = 480;
            const maxHeight = 640;
            let targetWidth = originWidth;
            let targetHeight = originHeight;
            if (originWidth > maxWidth && originHeight > maxHeight) {
              targetWidth = maxWidth;
              targetHeight = maxHeight;
            } else if (originWidth > maxWidth && originHeight < maxHeight) {
              targetWidth = originHeight;
              targetHeight = originHeight;
            } else if (originWidth < maxWidth && originHeight > maxHeight) {
              targetWidth = originWidth;
              targetHeight = originWidth;
            }

            // 图片压缩旋转
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            context.clearRect(0, 0, targetWidth, targetHeight); // 清除画布
            switch (Orientation) {
              case "":
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
                break;
              case 1:
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
                break;
              case 3: //需要180度旋转
                context.rotate(Math.PI);
                context.drawImage(
                  image,
                  -targetWidth,
                  -targetHeight,
                  targetWidth,
                  targetHeight
                );
                break;
              case 6: //需要顺时针（向左）90度旋转
                canvas.width = targetHeight;
                canvas.height = targetWidth;
                context.rotate(Math.PI / 2);
                context.drawImage(
                  image,
                  0,
                  -targetHeight,
                  targetWidth,
                  targetHeight
                );
                break;
              case 8: //需要逆时针（向右）90度旋转
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                context.rotate((3 * Math.PI) / 2);
                context.drawImage(
                  image,
                  -targetWidth,
                  0,
                  targetWidth,
                  targetHeight
                );
                break;
              default:
                context.drawImage(image, 0, 0, targetWidth, targetHeight);
            }

            const type = "image/jpeg";
            //将canvas元素中的图像转变为DataURL
            const dataurl = canvas.toDataURL(type);
            //抽取DataURL中的数据部分，从Base64格式转换为二进制格式
            const bin = atob(dataurl.split(",")[1]);
            //创建空的Uint8Array
            const buffer = new Uint8Array(bin.length);
            //将图像数据逐字节放入Uint8Array中
            for (let i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i);
            }

            // 图片上传
            let fileName =
              _this.random_string(6) +
              "_" +
              new Date().getTime() +
              "." +
              File.name.split(".").pop();
            let date = (new Date().toLocaleDateString() + "").replace(
              /\//g,
              ""
            );
            let blob = new Blob([buffer.buffer], { type }); //利用Uint8Array创建Blob对象
            client
              .multipartUpload("upload/" + date + "/" + fileName, blob, {
                progress: function (percentage) {
                  if (percentage) {
                    file.status = "done";
                    file.message = "上传成功";
                  }
                },
              })
              .then((res) => {
                let url =
                  "http://static-hiyunyu-com.oss-cn-hangzhou.aliyuncs.com/" +
                  res.name;
                getFileId(url).then(function (res) {
                  if (res.success) {
                    file.fileId = res.data;
                    file.url = url;

                    Toast("上传成功");
                  }
                });
              })
              .catch(() => {
                file.status = "failed";
                file.message = "上传失败";
                Toast(file.message);
              });
          };
        };
      });
    },

    // 随机生成文件名
    random_string(len) {
      len = len || 10;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      let maxPos = chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },

    // 【监听】保存并审批
    onSubmit() {
      if (this.params.terminateReason == "") {
        return Toast("请选择退租原因");
      }
      this.getContractExitImgSave();
      this.getContractExitSave();
    },

    // 【监听】关闭审批页面
    onCloseSon() {
      this.approveShow = false;
      this.$router.push("/task/taskList");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#contractExit {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
  padding: 0 0.15rem;
  background: $bg-color;
  box-sizing: border-box;
}

// 文本模块
.block-item {
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 0.1rem;
  margin: 0.15rem 0;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
  }
  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.02rem 0;
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.cell-item {
  margin: 0.15rem 0;
  > .van-cell {
    border-radius: 0.1rem;
  }
}

.mr-t {
  margin-top: 0.1rem;
}

.other-cost {
  border: 1px dashed #999;
  margin: 0.2rem 0;
  position: relative;
  > .other-cost-del {
    position: absolute;
    top: -0.12rem;
    right: -0.12rem;
    z-index: 1;
  }
}

.title-icon {
  display: inline-block;
  background: $theme-color;
  width: 0.04rem;
  height: 0.15rem;
  border-radius: 0 0.04rem 0.04rem 0;
  margin-right: 0.1rem;
  vertical-align: middle;
}
.title-span {
  font-size: 0.16rem;
  vertical-align: middle;
}

.block-foot {
  margin: 0.15rem 0;
}

.upload {
  padding: 0.05rem 0.06rem;
  p {
    font-size: 0.14rem;
    padding: 0 0.1rem;
    color: #646566;
  }
  .van-uploader {
    margin: 0.1rem;
  }
}
</style>